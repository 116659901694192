'use client';

import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';
import IconWrapper, { LucideIconName } from '~/core/ui/IconWrapper';
import { cn } from '~/core/ui/utils';

const buttonConfigurationRowReverseVariants = cva('', {
  variants: {
    icon: {
      leading: 'flex-row',
      trailing: 'flex-row-reverse'
    }
  },
  defaultVariants: {
    icon: 'leading'
  }
});

export const buttonConfigurationSizeIconLoadingVariants = cva('', {
  variants: {
    size: {
      xl: 'w-5 h-5',
      lg: 'w-[18px] h-[18px]',
      md: 'w-4 h-4',
      sm: 'w-3.5 h-3.5',
      xs: 'w-3 h-3'
    }
  },
  defaultVariants: {
    size: 'lg'
  }
});

const buttonConfigurationRootVariants = cva('', {
  variants: {
    variant: {
      xlNoLabelNoIconLeading: 'px-3 py-3 w-[48px] h-[48px]',
      xlNoLabelWithIconLeading: 'px-3 py-3 w-[48px] h-[48px]',
      xlWithLabelNoIconLeading: 'px-6 py-3 h-12',
      xlWithLabelWithIconLeading: 'pl-[22px] pr-6 py-3 h-12',
      xlWithChildren: 'pl-[22px] pr-6 py-3 h-12',

      lgNoLabelNoIconLeading: 'px-2.5 py-2.5 w-[42px] h-[42px]',
      lgNoLabelWithIconLeading: 'px-2.5 py-2.5 w-[42px] h-[42px]',
      lgWithLabelNoIconLeading: 'px-[18px] py-[9px] h-[42px]',
      lgWithLabelWithIconLeading: 'pl-4 pr-[18px] py-[9px] h-[42px]',
      lgWithChildren: 'pl-4 pr-[18px] py-[9px] h-[42px]',

      mdNoLabelNoIconLeading: 'px-[9px] py-[9px] w-[40px] h-[40px]',
      mdNoLabelWithIconLeading: 'px-[9px] py-[9px] w-[40px] h-[40px]',
      mdWithLabelNoIconLeading: 'px-[18px] py-2 h-[40px]',
      mdWithLabelWithIconLeading: 'pl-4 pr-[18px] py-2 h-[40px]',
      mdWithChildren: 'pl-4 pr-[18px] py-2 h-[40px]',

      smNoLabelNoIconLeading: 'px-[7px] py-[7px] w-8 h-8',
      smNoLabelWithIconLeading: 'px-[7px] py-[7px] w-8 h-8',
      smWithLabelNoIconLeading: 'px-3 py-1.5 h-8',
      smWithLabelWithIconLeading: 'pl-2.5 pr-3 py-1.5 h-8',
      smWithChildren: 'pl-2.5 pr-3 py-1.5 h-8',

      xsNoLabelNoIconLeading: 'px-[5px] py-[5px] w-6 h-6',
      xsNoLabelWithIconLeading: 'px-[5px] py-[5px] w-6 h-6',
      xsWithLabelNoIconLeading: 'px-2 py-[3px] h-6',
      xsWithLabelWithIconLeading: 'pl-1.5 pr-2 py-[3px] h-6',
      xsWithChildren: 'pl-1.5 pr-2 py-[3px] h-6',

      xlNoLabelNoIconTrailing: '',
      xlNoLabelWithIconTrailing: '',
      xlWithLabelNoIconTrailing: '',
      xlWithLabelWithIconTrailing: 'pl-6 pr-[22px]  h-[50px]',
      xlIconTrailingWithChildren: 'pl-6 pr-[22px]  h-[50px]',

      lgNoLabelNoIconTrailing: '',
      lgNoLabelWithIconTrailing: '',
      lgWithLabelNoIconTrailing: '',
      lgWithLabelWithIconTrailing: 'pl-[18px] pr-4 py-[9px] h-[42px]',
      lgIconTrailingWithChildren: 'pl-[18px] pr-4 py-[9px] h-[42px]',

      mdNoLabelNoIconTrailing: '',
      mdNoLabelWithIconTrailing: '',
      mdWithLabelNoIconTrailing: '',
      mdWithLabelWithIconTrailing: 'pl-[18px] pr-4 py-2 h-[40px]',
      mdIconTrailingWithChildren: 'pl-[18px] pr-4 py-2 h-[40px]',

      smNoLabelNoIconTrailing: '',
      smNoLabelWithIconTrailing: '',
      smWithLabelNoIconTrailing: '',
      smWithLabelWithIconTrailing: 'pl-3 pr-2.5 py-1.5 h-8',
      smIconTrailingWithChildren: 'pl-3 pr-2.5 py-1.5 h-8',

      xsNoLabelNoIconTrailing: '',
      xsNoLabelWithIconTrailing: '',
      xsWithLabelNoIconTrailing: '',
      xsWithLabelWithIconTrailing: 'pl-2 pr-1.5 py-[3px] h-6',
      xsIconTrailingWithChildren: 'pl-2 pr-1.5 py-[3px] h-6'
    },
    subVariant: {
      primaryGroup:
        'border-primary-600 dark:border-primary-300 dark:hover:bg-primary-300 dark:focus:bg-primary-300',
      secondaryGroup: 'border-gray-300 hover:bg-gray-50  dark:border-gray-600 ',
      default: ''
    },
    shape: {
      rounded: 'rounded',
      circular: 'rounded-full'
    },
    group: {
      default: '',
      group:
        '-ml-px first:rounded-l first:ml-0 rounded-none last:rounded-r border-[1px] border-solid'
    },
    disabled: {
      default: '',
      disabled: 'pointer-events-none'
    }
  },
  defaultVariants: {
    variant: 'lgNoLabelNoIconLeading',
    subVariant: 'default',
    shape: 'rounded',
    group: 'default',
    disabled: 'default'
  }
});

export const buttonConfigurationSpaceLabelWithIconVariants = cva('', {
  variants: {
    variant: {
      xlLeading: 'ml-2.5',
      lgLeading: 'ml-2.5',
      mdLeading: 'ml-2',
      smLeading: 'ml-2',
      xsLeading: 'ml-1.5',

      xlTrailing: 'mr-2.5',
      lgTrailing: 'mr-2.5',
      mdTrailing: 'mr-2',
      smTrailing: 'mr-2',
      xsTrailing: 'mr-1.5'
    }
  },
  defaultVariants: {
    variant: 'lgLeading'
  }
});

export const buttonIconVariants = cva('', {
  variants: {
    variant: {
      primaryNoDisabled: '',
      primaryWithDisabled: '',
      secondaryNoDisabled: 'text-gray-500 dark:text-gray-400',
      secondaryWithDisabled: 'text-gray-400',
      'secondary-destructiveNoDisabled': '',
      'secondary-destructiveWithDisabled': '',
      tertiaryNoDisabled: 'text-gray-500 dark:text-gray-400',
      tertiaryWithDisabled: 'text-gray-400',
      'tertiary-destructiveNoDisabled': '',
      'tertiary-destructiveWithDisabled': '',
      destructiveNoDisabled: '',
      destructiveWithDisabled: '',
      'destructive-outlinedNoDisabled': '',
      'destructive-outlinedWithDisabled': '',
      quaternaryNoDisabled: '',
      quaternaryWithDisabled: '',
      'quaternary-outlinedNoDisabled': '',
      'quaternary-outlinedWithDisabled': ''
    }
  },
  defaultVariants: {
    variant: 'primaryNoDisabled'
  }
});

export const sizeIconWithLabel = {
  xl: 20,
  lg: 18,
  md: 16,
  sm: 14,
  xs: 12
};

const sizeIcon = {
  xl: 24,
  lg: 22,
  md: 20,
  sm: 18,
  xs: 16
};

export const fontSizeButton: { [key: string]: string } = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-lg',
  lg: 'text-lg',
  xl: 'text-lg'
};

interface ButtonProps {
  configurations?: 'default' | 'reverse' | 'dashed' | 'ghost';
  type?:
    | 'primary'
    | 'secondary'
    | 'secondary-destructive'
    | 'tertiary'
    | 'tertiary-destructive'
    | 'destructive'
    | 'destructive-outlined'
    | 'quaternary';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  label?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  shape?: 'circular' | 'rounded';
  htmlType?: 'button' | 'submit' | 'reset';
  icon?: 'leading' | 'trailing';
  iconMenus?: LucideIconName;
  className?: string;
  isGroup?: boolean;
  children?: ReactNode;
  fontWeight?: string;
  autoFocus?: boolean;
  iconRootStyle?: boolean;
  showFocusRing?: boolean;
}

const DashedButton = (
  props: ButtonProps & {
    baseButton: string;
    checkRowReverse: string;
    spaceLabelWithIcon: string;
  }
) => {
  const { configurations, isDisabled } = props;
  const classNameButton = cn(
    configurations === 'dashed' &&
      isDisabled == false &&
      'text-gray-500 dark:text-gray-400 bg-transparent hover:bg-gray-50 dark:hover:bg-gray-800 border-[1px] border-dashed border-gray-300 dark:border-gray-600',
    configurations === 'dashed' &&
      isDisabled == true &&
      'text-gray-400 dark:text-gray-600 bg-transparent border-[1px] border-dashed border-gray-200 dark:border-gray-700'
  );

  return <DefaultButtonView {...props} classNameButton={classNameButton} />;
};

const DefaultButton = (
  props: ButtonProps & {
    baseButton: string;
    checkRowReverse: string;
    spaceLabelWithIcon: string;
  }
) => {
  const { configurations, type, isDisabled, isLoading, label, showFocusRing } =
    props;
  const focusName = cva('ring-2 ring-offset-2', {
    variants: {
      type: {
        primary: 'ring-primary-300 dark:ring-primary-700',
        secondary: 'ring-primary-300 dark:ring-primary-700',
        'secondary-destructive': '',
        tertiary:
          'ring-offset-2 ring-gray-400 dark:ring-offset-gray-900 dark:ring-gray-600',
        'tertiary-destructive': '',
        destructive:
          'ring-gray-400 dark:ring-offset-gray-900 dark:ring-gray-600',
        'destructive-outlined': '',
        quaternary: ''
      }
    }
  })({ type });
  const classNameButton = cn(
    configurations === 'default' && '',
    type === 'primary' &&
      (isDisabled || isLoading) == false &&
      'text-white bg-primary-400 hover:bg-primary-600  dark:hover:bg-primary-300 ',
    type === 'secondary' &&
      label &&
      (isDisabled || isLoading) == false &&
      'text-gray-600 dark:text-gray-300 bg-white dark:bg-gray-900 border-[1px] border-solid border-gray-300 dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800   ',
    type === 'secondary' &&
      !label &&
      (isDisabled || isLoading) == false &&
      'text-gray-500 dark:text-gray-300 bg-white dark:bg-gray-900 border-[1px] border-solid border-gray-300 dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800   ',
    type === 'tertiary' &&
      (isDisabled || isLoading) == false &&
      'text-gray-600 dark:text-gray-300 bg-gray-100 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600',
    type === 'destructive' &&
      (isDisabled || isLoading) == false &&
      'text-white bg-red-500 hover:bg-red-600',
    type === 'destructive-outlined' &&
      (isDisabled || isLoading) == false &&
      'text-red-500 bg-white dark:bg-gray-900 border-[1px] border-solid border-gray-300 dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800',
    type === 'quaternary' &&
      (isDisabled || isLoading) == false &&
      'text-white bg-gray-800 hover:bg-gray-700',
    (isDisabled || isLoading) == true &&
      type === 'primary' &&
      'bg-primary-200 dark:bg-primary-800 text-white dark:text-gray-400',
    (isDisabled || isLoading) == true &&
      type === 'secondary' &&
      'border-gray-200 text-gray-400 dark:border-gray-700 dark:text-gray-600 border-[1px] border-solid dark:border-gray-600',
    (isDisabled || isLoading) == true &&
      type === 'tertiary' &&
      'bg-gray-50 text-gray-400 dark:bg-gray-800 dark:text-gray-600',
    (isDisabled || isLoading) == true &&
      type === 'destructive' &&
      'bg-red-300 dark:bg-red-700 text-white dark:text-gray-400',
    (isDisabled || isLoading) == true &&
      type === 'destructive-outlined' &&
      'text-red-300 dark:text-red-700 border-[1px] border-solid border-gray-200 dark:border-gray-700',
    showFocusRing && focusName
  );
  const spinLoading = cn(
    ['primary', 'destructive'].includes(type || 'primary') &&
      'fill-white dark:fill-gray-400',
    ['secondary', 'tertiary'].includes(type || 'primary') &&
      'fill-gray-400 dark:fill-gray-600',
    type === 'destructive-outlined' && 'fill-red-300 dark:fill-red-700'
  );

  return (
    <DefaultButtonView
      {...props}
      classNameButton={classNameButton}
      spinLoading={spinLoading}
    />
  );
};

const GhostButton = (
  props: ButtonProps & {
    baseButton: string;
    checkRowReverse: string;
    spaceLabelWithIcon: string;
  }
) => {
  const { configurations, type, isDisabled, label } = props;
  const classNameButton = cn(
    configurations === 'ghost' && isDisabled == false && 'bg-transparent',
    type === 'primary' &&
      isDisabled == false &&
      'text-primary-400 hover:bg-gray-100 dark:hover:bg-gray-700  ',
    type === 'secondary' &&
      label &&
      isDisabled == false &&
      'text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800',
    type === 'secondary' &&
      !label &&
      isDisabled == false &&
      'text-gray-500 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800  ',
    type === 'tertiary' &&
      label &&
      isDisabled == false &&
      'text-gray-500 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-800  ',
    type === 'tertiary' &&
      !label &&
      isDisabled == false &&
      'text-gray-400 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800  ',
    type === 'secondary-destructive' &&
      label &&
      isDisabled == false &&
      'text-gray-600 dark:text-gray-300 hover:text-red-500  hover:bg-gray-50 dark:hover:bg-gray-800  ',
    type === 'secondary-destructive' &&
      !label &&
      isDisabled == false &&
      'text-gray-500 dark:text-gray-300 hover:text-red-500 hover:bg-gray-100 dark:hover:bg-gray-800  ',
    type === 'tertiary-destructive' &&
      label &&
      isDisabled == false &&
      'text-gray-500 dark:text-gray-400 hover:text-red-500  hover:bg-gray-50 dark:hover:bg-gray-800  ',
    type === 'tertiary-destructive' &&
      !label &&
      isDisabled == false &&
      'text-gray-400 dark:text-gray-400 hover:text-red-500 hover:bg-gray-100 dark:hover:bg-gray-800 ',
    type === 'destructive' &&
      isDisabled == false &&
      'text-red-500 hover:bg-gray-50 dark:hover:bg-gray-800',
    type === 'destructive' &&
      !label &&
      isDisabled === false &&
      'hover:bg-gray-100  ',
    type === 'primary' &&
      isDisabled == true &&
      'text-primary-200 dark:text-primary-800',
    [
      'secondary',
      'tertiary',
      'secondary-destructive',
      'tertiary-destructive'
    ].includes(type || 'secondary') &&
      isDisabled == true &&
      'text-gray-400 dark:text-gray-600',
    type === 'destructive' &&
      isDisabled == true &&
      'text-red-300 dark:text-red-700'
  );

  return <DefaultButtonView {...props} classNameButton={classNameButton} />;
};

const ReverseButton = (
  props: ButtonProps & {
    baseButton: string;
    checkRowReverse: string;
    spaceLabelWithIcon: string;
  }
) => {
  const { configurations, type, isDisabled, isLoading } = props;

  const classNameButton = cn(
    type === 'primary' &&
      (isDisabled || isLoading) == false &&
      'text-primary-400 bg-white hover:bg-primary-100  ',
    type === 'secondary' &&
      (isDisabled || isLoading) == false &&
      'text-white bg-transparent border-[1px] border-solid border-white hover:bg-primary-500',
    type === 'primary' &&
      (isDisabled || isLoading) == true &&
      'text-primary-400 bg-primary-300',
    type === 'secondary' &&
      (isDisabled || isLoading) == true &&
      'text-primary-300 bg-primary-400 border-[1px] border-solid border-primary-300'
  );
  const spinLoading = cn(
    type === 'primary' && 'fill-primary-400',
    type === 'secondary' && 'fill-primary-300'
  );

  return (
    <DefaultButtonView
      {...props}
      classNameButton={classNameButton}
      spinLoading={spinLoading}
    />
  );
};

const DefaultButtonView = (
  props: ButtonProps & {
    baseButton: string;
    checkRowReverse: string;
    classNameButton: string;
    spinLoading?: string;
    spaceLabelWithIcon: string;
    sizeIconLoading?: string;
  }
) => {
  const {
    iconMenus,
    label,
    checkRowReverse,
    children,
    htmlType = 'button',
    onClick,
    baseButton,
    classNameButton,
    className,
    isLoading,
    spinLoading,
    size = 'lg',
    fontWeight,
    spaceLabelWithIcon,
    sizeIconLoading,
    autoFocus,
    isDisabled,
    type
  } = props;

  const renderTitleButton = () => {
    if (iconMenus && label) {
      return (
        <div className={`flex items-center justify-between ${checkRowReverse}`}>
          <div className="flex items-center">
            <IconWrapper
              className={cn(
                buttonIconVariants({
                  variant: `${type || 'primary'}${
                    isDisabled ? 'WithDisabled' : 'NoDisabled'
                  }`
                })
              )}
              name={iconMenus}
              size={sizeIconWithLabel[size || 'lg']}
            />
          </div>
          <div
            className={`flex items-center justify-center ${spaceLabelWithIcon}`}
          >
            <span className={`${fontSizeButton[size]} ${fontWeight}`}>
              {label}
            </span>
          </div>
        </div>
      );
    }
    if (label) {
      return (
        <span className={`${fontSizeButton[size]} ${fontWeight}`}>{label}</span>
      );
    }
    if (iconMenus) {
      return (
        <div className={`flex items-center justify-center`}>
          <IconWrapper
            className={cn(
              buttonIconVariants({
                variant: `${type || 'primary'}${
                  isDisabled ? 'WithDisabled' : 'NoDisabled'
                }`
              })
            )}
            name={iconMenus}
            size={sizeIcon[size || 'lg']}
          />
        </div>
      );
    }
    if (children) {
      return (
        <div
          className={`${fontSizeButton[size]} ${fontWeight} max-w-full flex-1`}
        >
          {children}
        </div>
      );
    }
    return '';
  };

  return (
    <button
      autoFocus={autoFocus}
      type={htmlType}
      onClick={(event) => {
        if (onClick) {
          onClick(event);
        }
      }}
      className={`flex items-center justify-center ${baseButton} ${classNameButton} ${className}`}
    >
      {isLoading ? (
        <div className={`flex items-center justify-center ${checkRowReverse}`}>
          <div>
            <svg
              aria-hidden="true"
              className={`animate-spin text-transparent ${sizeIconLoading} ${spinLoading}`}
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>
          {label && (
            <div
              className={`flex items-center justify-center ${spaceLabelWithIcon}`}
            >
              <span className={`${fontSizeButton[size]} ${fontWeight}`}>
                {label}
              </span>
            </div>
          )}
          {children && <div className={spaceLabelWithIcon}>{children}</div>}
        </div>
      ) : (
        renderTitleButton()
      )}
    </button>
  );
};

const Button = ({ ...props }: ButtonProps) => {
  const {
    label,
    configurations = 'default',
    type = 'primary',
    size = 'lg',
    shape = 'rounded',
    isDisabled = false,
    isGroup = false,
    isLoading = false,
    icon = 'leading',
    fontWeight = 'font-medium',
    iconMenus,
    autoFocus,
    iconRootStyle
  } = props;
  const baseButton = buttonConfigurationRootVariants({
    variant:
      props.children && iconRootStyle
        ? `${size}IconTrailingWithChildren`
        : `${size}${label ? 'WithLabel' : 'NoLabel'}${
            iconMenus ? 'WithIcon' : 'NoIcon'
          }${icon === 'leading' ? 'Leading' : 'Trailing'}`,
    subVariant:
      (type === 'primary' && isGroup) || (type === 'secondary' && isGroup)
        ? `${type}Group`
        : 'default',
    shape,
    group: isGroup ? 'group' : 'default',
    disabled: isDisabled == true || isLoading == true ? 'disabled' : 'default'
  });

  const checkRowReverse = buttonConfigurationRowReverseVariants({ icon });
  const spaceLabelWithIcon = buttonConfigurationSpaceLabelWithIconVariants({
    variant: `${size}${icon === 'leading' ? 'Leading' : 'Trailing'}`
  });
  const sizeIconLoading = buttonConfigurationSizeIconLoadingVariants({ size });

  const mergedOptions = {
    type,
    fontWeight,
    isDisabled,
    isLoading,
    baseButton,
    checkRowReverse,
    spaceLabelWithIcon,
    sizeIconLoading,
    autoFocus,
    configurations
  };

  const renderButton = () => {
    if (configurations === 'default') {
      return <DefaultButton {...props} {...mergedOptions} />;
    }
    if (configurations === 'reverse') {
      return <ReverseButton {...props} {...mergedOptions} />;
    }
    if (configurations === 'dashed') {
      return <DashedButton {...props} {...mergedOptions} />;
    }
    if (configurations === 'ghost') {
      return <GhostButton {...props} {...mergedOptions} />;
    }
    return <button />;
  };

  return renderButton();
};

Button.displayName = 'Button';

export { Button, DashedButton, DefaultButton, GhostButton, ReverseButton };
export type { ButtonProps };
